<template>
  <div class="profile-settings">
    <div class="profile-container">
      <!-- Left Column: Avatar Selection -->
      <div class="profile-left">
        <!-- Current Avatar with Border -->
        <div class="avatar-border">
          <img :src="currentAvatarPath" alt="Current Avatar" class="avatar-image" />
        </div>

        <!-- Change Avatar Button -->
        <button class="change-avatar-button blue-button" @click="toggleAvatarList">
          CHANGE AVATAR
        </button>

        <!-- Avatar Options (Shown when the button is clicked) -->
        <div v-if="showAvatarOptions" class="avatar-options-list">
          <div class="avatar-options">
            <div v-for="n in 10" :key="n" class="avatar-option" @click="selectAvatar(n)">
              <img :src="getAvatarPath(n)" :alt="'Avatar ' + n" class="avatar-thumbnail" />
            </div>
          </div>
        </div>
      </div>

      <!-- Right Column: Form Fields -->
      <div class="profile-right">
        <!-- Row 1: Name and Last Name -->
        <div class="row">
          <div class="input-group">
            <label for="name" class="label-text">NAME</label>
            <input type="text" id="name" class="input-field" v-model="profile.name" placeholder="First Name" />
          </div>
          <div class="input-group">
            <label for="lastName" class="label-text">LAST NAME</label>
            <input type="text" id="lastName" class="input-field" v-model="profile.last_name" placeholder="Last Name" />
          </div>
        </div>

        <!-- Row 2: About Me -->
        <div class="row">
          <div class="input-group full-width">
            <label for="aboutMe" class="label-text">ABOUT ME</label>
            <textarea id="aboutMe" class="input-field" rows="3" v-model="profile.about_me" placeholder="Describe yourself..."></textarea>
          </div>
        </div>

        <!-- Row 3: Email and Role -->
        <div class="row">
          <div class="input-group">
            <label for="email" class="label-text">EMAIL</label>
            <input type="email" id="email" class="input-field" v-model="profile.email" placeholder="Email" />
          </div>
          <div class="input-group">
            <label for="role" class="label-text">ROLE</label>
            <select id="role" v-model="profile.role" class="input-field">
              <option value="SuperAdmin">SuperAdmin</option>
              <option value="Teacher">Teacher</option>
              <option value="Student">Student</option>
            </select>
          </div>
        </div>

        <!-- Row 4: Change Password Selector -->
        <div class="row switch-container">
          <label class="label-text">Change Password?</label>
          <div class="switch" @click="togglePasswordFields">
            <input type="checkbox" v-model="showPasswordFields" />
            <span class="slider"></span>
          </div>
        </div>

        <!-- Row 5: Password Fields (Shown only if Change Password is selected) -->
        <div v-if="showPasswordFields" class="row">
          <div class="input-group">
            <label for="newPassword" class="label-text">New Password</label>
            <input 
              :type="passwordVisible ? 'text' : 'password'" 
              id="newPassword" 
              class="input-field" 
              v-model="passwords.new" 
              placeholder="New Password" 
            />
            <span class="eye-icon" @click="togglePasswordVisibility">
              <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
            </span>
          </div>
          <div class="input-group">
            <label for="confirmPassword" class="label-text">Confirm Password</label>
            <input 
              :type="passwordVisible ? 'text' : 'password'" 
              id="confirmPassword" 
              class="input-field" 
              v-model="passwords.confirm" 
              placeholder="Confirm Password" 
            />
            <span class="eye-icon" @click="togglePasswordVisibility">
              <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
            </span>
          </div>
    </div>

        <!-- Save Button (Styled like a pill) -->
        <div class="row">
          <button class="save-button blue-button" @click="saveProfile">
            SAVE
          </button>
        </div>
        <!-- Feedback messages -->
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

  
  <script>
import { ref, inject, computed, onMounted } from 'vue';
import axios from 'axios'; // Ensure axios is imported


// Import all avatars statically
import Avatar1 from '@/assets/MD-Avatar-1.webp';
import Avatar2 from '@/assets/MD-Avatar-2.webp';
import Avatar3 from '@/assets/MD-Avatar-3.webp';
import Avatar4 from '@/assets/MD-Avatar-4.webp';
import Avatar5 from '@/assets/MD-Avatar-5.webp';
import Avatar6 from '@/assets/MD-Avatar-6.webp';
import Avatar7 from '@/assets/MD-Avatar-7.webp';
import Avatar8 from '@/assets/MD-Avatar-8.webp';
import Avatar9 from '@/assets/MD-Avatar-9.webp';
import Avatar10 from '@/assets/MD-Avatar-10.webp';

export default {
  name: 'ProfileSettings',
  setup() {
    const showPasswordFields = ref(false);
    const avatarNumber = inject('avatarNumber');
    const showAvatarOptions = ref(false);
    const passwordVisible = ref(false); // Control password visibility
    const profile = ref({
      name: '',
      last_name: '',
      email: '',
      about_me: '',
      role: 'Student', // Default role set to Student
    });
    const passwords = ref({
      new: '',
      confirm: '',
    });

    const successMessage = ref('');
    const errorMessage = ref('');

    const avatarPaths = [
      Avatar1, Avatar2, Avatar3, Avatar4, Avatar5,
      Avatar6, Avatar7, Avatar8, Avatar9, Avatar10
    ];

    const currentAvatarPath = computed(() => avatarPaths[avatarNumber.value - 1]);

    const togglePasswordFields = () => {
      showPasswordFields.value = !showPasswordFields.value;
    };

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const toggleAvatarList = () => {
      showAvatarOptions.value = !showAvatarOptions.value;
    };

    const getAvatarPath = (n) => avatarPaths[n - 1];

    const selectAvatar = (n) => {
      avatarNumber.value = n;
      localStorage.setItem('selectedAvatar', n); // Store only the avatar number in localStorage
      showAvatarOptions.value = false;
    };
 
    const loadProfile = async () => {
  try {
    const token = localStorage.getItem('jwt');  // Retrieve the JWT token from local storage
    if (!token) {
      console.error("No token found in local storage");
      return;
    }
    
    console.log('JWT attached to request:', token);  // Log the token to verify it is correct
    
    const response = await axios.get('/yaga-auth/profile', {
      headers: {
        Authorization: `Bearer ${token}`  // Include the token in the request headers
      }
    });

    // Assuming the response contains all user data fields needed for the profile
    profile.value = {
      name: response.data.name,
      last_name: response.data.last_name,
      email: response.data.email,
      about_me: response.data.about_me,
      avatar: response.data.avatar,
      role: response.data.role || 'Student',  // Set a default role if not provided
    };

    console.log('Loaded user profile:', profile.value);  // Log the loaded profile data

  } catch (error) {
    console.error('Error loading profile data:', error.response ? error.response.data : error.message);
    errorMessage.value = 'Error loading profile data.';
  }
};

onMounted(() => {
  loadProfile();
});

const saveProfile = async () => {
  try {
    const token = localStorage.getItem('jwt');  // Retrieve the JWT token from local storage
    if (!token) {
      console.error("No token found in local storage");
      return;
    }
    if (passwords.value.new !== passwords.value.confirm) {
          errorMessage.value = "Passwords do not match.";
          return;
        }

    const payload = {
      ...profile.value,
      avatar: avatarNumber.value,  // Include avatar selection
      role: profile.value.role,  // Include role selection
      password: passwords.value.new || null,  // Only send the new password if it's provided
    };

    const response = await axios.post('/yaga-auth/profile', payload, {
      headers: {
        Authorization: `Bearer ${token}`  // Include token in the request headers
      }
    });

    console.log('Response after saving profile:', response.data);
    successMessage.value = 'Profile updated successfully!';
    errorMessage.value = '';
    // Clear passwords
    passwords.value.new = '';
    passwords.value.confirm = '';
  } catch (error) {
    console.error('Error updating profile:', error.response ? error.response.data : error.message);
    errorMessage.value = 'Error updating profile. Please try again.';
    successMessage.value = '';
  }
};





 
    return {
      showPasswordFields,
      avatarNumber,
      currentAvatarPath,
      profile,
      passwords,
      showAvatarOptions,
      togglePasswordFields,
      toggleAvatarList,
      selectAvatar,
      getAvatarPath,
      saveProfile,
      successMessage,
      errorMessage,
      passwordVisible,
      togglePasswordVisibility,

    };
  }
};
</script>
  
  <style scoped>
  .profile-settings {
    background: linear-gradient(to bottom, #000814, #0d1b2a); /* Background gradient */
    padding: 20px;
    color: white; /* Default text color */
  }
  
  .profile-container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 100px); /* Adjust height to fit the content area */
  }
  
  .profile-left {
    width: 33%;
    padding: 20px;
    background-color: #1b263b; /* Dark background for profile section */
    border-radius: 8px;
  }
  
  .avatar-border {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Yellow border background */
    border-radius: 50%;
    padding: 10px; /* Adjust to control the size of the border */
    width: 170px;
    height: 170px;
    margin: 0 auto;
  }
  
  .avatar-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
/* Basic styles for buttons */
.change-avatar-button,
.save-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Default gradient */
  color: #000814;
  border-radius: 25px;
  padding: 10px 30px;
  margin: 10px auto;
  display: block;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s, filter 0.3s; /* Smooth transition for the background and filter */
  border: none;
  outline: none;
}

/* Hover effect - make the button slightly darker on hover */
.change-avatar-button:hover,
.save-button:hover {
  filter: brightness(1.3); /* Darken the button on hover */
}

/* Click effect - show the clicked state */
.change-avatar-button:active,
.save-button:active {
  box-shadow: inset 0 6px 8px rgba(0, 0, 0, 0.3); /* Simulate the button being pressed */
}

/* Specific classes for button colors */
.blue-button {
  background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Blue gradient */
  color: #000814;
}

.yellow-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Yellow gradient */
  color: #000814;
}
.success-message {
  color: #4CAF50;
  font-weight: bold;
  margin-top: 10px;
}

.error-message {
  color: #FF5252;
  font-weight: bold;
  margin-top: 10px;
}


  .avatar-options-list {
    margin-top: 20px;
  }
  
  .avatar-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .avatar-option {
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .avatar-thumbnail {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-right {
    width: 67%;
    padding: 20px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .switch-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    width: 48%; /* 48% to leave space for the two-column layout */
  }
  
  .full-width {
    width: 100%; /* Full width for rows that need the full space */
  }
  
  textarea, input {
    background-color: #1b263b;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    color: lightgray;
    font-weight: normal;
  }
  
  
  .label-text {
    font-weight: bold;
    color: white; /* Title text in white */
  }
  
  /* Toggle switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-left: 15px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: gray;
    transition: 0.4s;
    border-radius: 25px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    border-radius: 50%;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #03abff;
  }
  
  input:checked + .slider:before {
    transform: translateX(24px);
  }

  /* Apply styles to the select dropdown */
select.input-field {
  background-color: #1b263b; /* Black background for the dropdown */
  color: white; /* White text color */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
}

/* Styling the dropdown options */
select.input-field option {
  background-color: #1b263b; /* Black background for options */
  color: white; /* White text color */
}

/* Highlight color for options */
select.input-field option:hover {
  background-color: #ffc300; /* Yellow background on hover */
}

.input-field {
  width: 100%; /* Make sure input takes full width */
  padding-right: 30px; /* Add padding to the right to accommodate the eye icon */
}

.eye-icon {
  position: relative;
  align-self: flex-end;
  right: 10px;
  top: -35%;
  cursor: pointer;
  color: lightgray; /* Adjust color as needed */
}
  </style>
  