<template>
  <div class="add-user-container" v-if="!isEditing">
  <div class="add-user-container">
    <!-- Button to Create New User -->
    <button class="create-user-button" @click="toggleAddUserSection">
      <font-awesome-icon :icon="['fas', 'plus-circle']" /><span class="button-text">CREATE NEW USER</span>
    </button>

    <!-- Add New User Form Section -->
    <div v-if="showAddUserSection" class="add-new-user">
      <div class="form-container">
        <!-- Row 1: Name and Last Name -->
        <div class="row">
          <div class="input-group">
            <label for="name" class="label-text">NAME</label>
            <input type="text" id="name" class="input-field" v-model="user.name" placeholder="First Name" />
          </div>
          <div class="input-group">
            <label for="lastName" class="label-text">LAST NAME</label>
            <input type="text" id="lastName" class="input-field" v-model="user.last_name" placeholder="Last Name" />
          </div>
        </div>
  
        <!-- Row 2: Email and Role -->
        <div class="row">
          <div class="input-group">
            <label for="email" class="label-text">EMAIL</label>
            <input type="email" id="email" class="input-field" v-model="user.email" placeholder="Email" />
          </div>
          <div class="input-group">
            <label for="role" class="label-text">ROLE</label>
            <select id="role" v-model="user.role" class="input-field">
              <option value="SuperAdmin">SuperAdmin</option>
              <option value="Teacher">Teacher</option>
              <option value="Student">Student</option>
            </select>
          </div>
        </div>
  
        <!-- Row 3: Password Fields -->
        <div class="row">
          <div class="input-group">
            <label for="newPassword" class="label-text">New Password</label>
            <input 
              :type="passwordVisible ? 'text' : 'password'" 
              id="newPassword" 
              class="input-field" 
              v-model="passwords.new" 
              placeholder="New Password" 
            />
            <span class="eye-icon" @click="togglePasswordVisibility">
              <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
            </span>
          </div>
          <div class="input-group">
            <label for="confirmPassword" class="label-text">Confirm Password</label>
            <input 
              :type="passwordVisible ? 'text' : 'password'" 
              id="confirmPassword" 
              class="input-field" 
              v-model="passwords.confirm" 
              placeholder="Confirm Password" 
            />
            <span class="eye-icon" @click="togglePasswordVisibility">
              <i :class="passwordVisible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"></i>
            </span>
          </div>
        </div>
  
        <!-- Save Button -->
        <div class="row">
          <button class="save-button blue-button" @click="addUser">
            ADD USER
          </button>
        </div>
        <!-- Feedback messages -->
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </div>

 <!-- User List and Search -->
 <div class="user-list-container">
      <h4>List of Users</h4>
        <!-- Search Bar -->
        <div class="search-container">
          <input 
            type="text" 
            v-model="searchQuery" 
            placeholder="Search users..." 
            class="search-bar" 
          />

              <!-- Conditionally show the Edit and Delete buttons -->
    <div class="action-buttons" v-if="selectedUsers.length === 1">
      <button class="edit-user-button" @click="editUser">
        <font-awesome-icon :icon="['fas', 'pencil-square-o']" /> <span class="button-text">EDIT SELECTED USER</span>
      </button>
      <button class="delete-user-button" @click="deleteSelectedUsers" :disabled="!selectedUsers.length">
        <font-awesome-icon :icon="['fas', 'trash']" /> <span class="button-text">DELETE SELECTED USERS</span>
      </button>
    </div>
        </div>

      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in paginatedUsers" :key="user.id">
            <td>
              <input type="checkbox" v-model="selectedUsers" :value="user.id" />
            </td>
            <td>{{ user.name }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
          </tr>
        </tbody>
      </table>

<!-- Pagination Controls -->
<div class="pagination-controls" v-if="filteredUsers && filteredUsers.length > usersPerPage">
  <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
  <span>Page {{ currentPage }} of {{ totalPages }}</span>
  <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
</div>

    </div>
  </div>

<!-- Edit User Component -->
<EditYagaUser v-if="isEditing" :userId="selectedUsers[0]" @cancelEdit="cancelEdit" />
  </template>
  
  <script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import EditYagaUser from './EditYagaUser.vue'; // Import the edit component


export default {
  name: 'AddYagaUser',
  components: {
    EditYagaUser,
  },
  setup() {


    // State for new user form
    const user = ref({
      name: '',
      last_name: '',
      email: '',
      role: 'Student',
    });

    const passwords = ref({
      new: '',
      confirm: '',
    });

    const passwordVisible = ref(false);
    const successMessage = ref('');
    const errorMessage = ref('');

    // State for users list
    const users = ref([]);
    const showAddUserSection = ref(false);
    const selectedUsers = ref([]);
    const searchQuery = ref('');
    const currentPage = ref(1);
    const usersPerPage = 12;

    // Computed for filtered and paginated users
    const filteredUsers = computed(() => {
  return (users.value || []).filter(user => 
    user.name?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.last_name?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
    user.role?.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});


    const totalPages = computed(() => Math.ceil(filteredUsers.value.length / usersPerPage));

    const paginatedUsers = computed(() => {
      const start = (currentPage.value - 1) * usersPerPage;
      const end = start + usersPerPage;
      return filteredUsers.value.slice(start, end);
    });

    // Method to toggle password visibility
    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const toggleAddUserSection = () => {
      showAddUserSection.value = !showAddUserSection.value;
    };

    // Method to add a new user
    const addUser = async () => {
      try {
        if (passwords.value.new !== passwords.value.confirm) {
          errorMessage.value = "Passwords do not match.";
          return;
        }

        const payload = {
          ...user.value,
          password: passwords.value.new,
        };

        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.post('/yaga-auth/add-user', payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Response after adding user:', response.data);
        successMessage.value = 'User added successfully!';
        errorMessage.value = '';
        
        // Clear the form
        user.value = {
          name: '',
          last_name: '',
          email: '',
          role: 'Student',
        };
        passwords.value.new = '';
        passwords.value.confirm = '';

        // Reload the users list after adding a new user
        await loadUsers();

      } catch (error) {
        console.error('Error adding user:', error.response ? error.response.data : error.message);
        errorMessage.value = 'Error adding user. Please try again.';
        successMessage.value = '';
      }
    };

    // Method to load all users
    const loadUsers = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.get('/yaga-auth/all-users', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        users.value = response.data;
        console.log('Loaded users:', users.value);

      } catch (error) {
        console.error('Error loading users:', error.response ? error.response.data : error.message);
        errorMessage.value = 'Error loading users.';
      }
    };

    // Method to delete selected users
    const deleteSelectedUsers = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.post('/yaga-auth/delete-users', {
          userIds: selectedUsers.value
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Response after deleting users:', response.data);
        successMessage.value = 'Users deleted successfully!';
        errorMessage.value = '';
        selectedUsers.value = [];
        
        // Reload the users list after deleting
        await loadUsers();

      } catch (error) {
        console.error('Error deleting users:', error.response ? error.response.data : error.message);
        errorMessage.value = 'Error deleting users. Please try again.';
        successMessage.value = '';
      }
    };

    // Pagination control methods
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const isEditing = ref(false);
        // Method to edit a user
        const editUser = () => {
      isEditing.value = true; // Show the edit user component
    };

    // Method to cancel editing
    const cancelEdit = () => {
      isEditing.value = false; // Hide the edit user component
      loadUsers(); // Reload users
    };


    // Load users when the component is mounted
    onMounted(() => {
      loadUsers();
    });

    return {
      user,
      passwords,
      passwordVisible,
      togglePasswordVisibility,
      addUser,
      successMessage,
      errorMessage,
      users,
      showAddUserSection,
      toggleAddUserSection,
      selectedUsers,
      searchQuery,
      paginatedUsers,
      totalPages,
      currentPage,
      nextPage,
      prevPage,
      deleteSelectedUsers,
      isEditing,
      editUser,
      cancelEdit,
    };
  }
};
</script>

  
  
  <style scoped>
  .add-user-container {
  padding: 20px;
}

/* Button to Create New User */
.create-user-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
}

.create-user-button:hover {
  filter: brightness(0.9);
}
  .add-new-user {
    margin-bottom: 20px;

  }
  
  .form-title {
  color: #03abff;
  margin-bottom: 10px;
}
.label-text {
  color: #ffc300;
  font-weight: bold;
}
  .form-container {
    width: 100%;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    width: 48%; /* 48% to leave space for the two-column layout */
  }
  
  .input-field {
    background-color: #1b263b;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    color: lightgray;
    font-weight: normal;
  }
  

  
  .eye-icon {
    position: relative;
    align-self: flex-end;
    right: 10px;
    top: -35%;
    cursor: pointer;
    color: lightgray; /* Adjust color as needed */
  }
  
  .save-button {
    background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Default gradient */
    color: #000814;
    border-radius: 25px;
    padding: 10px 30px;
    margin: 10px auto;
    display: block;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, filter 0.3s; /* Smooth transition for the background and filter */
    border: none;
    outline: none;
  }
  
  .save-button:hover {
    filter: brightness(1.3); /* Darken the button on hover */
  }
  
  .save-button:active {
    box-shadow: inset 0 6px 8px rgba(0, 0, 0, 0.3); /* Simulate the button being pressed */
  }
  
  .success-message {
    color: #4CAF50;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .error-message {
    color: #FF5252;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Apply styles to the select dropdown */
  select.input-field {
    background-color: #1b263b; /* Black background for the dropdown */
    color: white; /* White text color */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
  }
  
  /* Styling the dropdown options */
  select.input-field option {
    background-color: #1b263b; /* Black background for options */
    color: white; /* White text color */
  }
  
  /* Highlight color for options */
  select.input-field option:hover {
    background-color: #ffc300; /* Yellow background on hover */
  }


.error-message {
  color: #FF5252;
  font-weight: bold;
  margin-top: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
/* User List Table */
.user-list-title {
  color: #03abff;
  margin-bottom: 10px;
}
th {
  color: #ffc300;
  text-align: left;
  padding: 8px;
}

td {
  color: #9ad7ff;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

h4 {
  color: #03abff
}


.delete-user-button {
  background: linear-gradient(to bottom, #ff0000, #920000);
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
  margin-left: 20px; /* Add some space between the create and delete buttons */
}

.delete-user-button:hover {
  filter: brightness(0.9);
}
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-right: 20px;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  width: 33%; /* Set width to one-third of the container */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.button-text {
  margin-left: 10px;
}

/* Button to Create New User */
.edit-user-button {
  background: linear-gradient(to bottom, #ffc300, #ffd60a);
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: bold;
}


.edit-user-button:hover {
  filter: brightness(0.9);
}
  </style>