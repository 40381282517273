<template>
  <v-app>
    <div class="dashboard-layout">
      <!-- Conditionally hide the dashboard layout when LLBuilder is active -->
      <div v-if="selectedMenu !== 'llbuilder'" class="dashboard-container">
        <!-- Left Column (Menu) -->
        <div class="dashboard-menu">
          <!-- Display Avatar above the menu -->
          <div class="avatar-border">
            <img :src="selectedAvatarPath" alt="User Avatar" class="avatar-image" />
          </div>

          <div class="menu-list">
            <div 
              :class="{ active: selectedMenu === 'homepage' }" 
              @click="selectMenu('homepage')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'home']" />
              <span class="menu-text">HOME PAGE</span>
            </div>
            <div v-if="isSuperUser"
              :class="{ active: selectedMenu === 'yagausers' }"
              @click="selectMenu('yagausers')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'user-shield']" />
              <span class="menu-text">YAGA USERS</span>
            </div>
            <div v-else 
              :class="{ active: selectedMenu === 'firststeps' }" 
              @click="selectMenu('firststeps')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'rocket']" />
              <span class="menu-text">FIRST STEPS</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'llbuilder' }" 
              @click="selectMenu('llbuilder')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'code-branch']" :rotation="90" />
              <span class="menu-text">LADDER LOGIC BUILDER</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'digitaltwin' }" 
              @click="selectMenu('digitaltwin')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'cubes']" />
              <span class="menu-text">DIGITAL TWIN ENVIRONMENTS</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'iiotdata' }" 
              @click="selectMenu('iiotdata')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" />
              <span class="menu-text">IIoT DATA SOURCES</span>
            </div>
            <div 
              :class="{ active: selectedMenu === 'profile' }" 
              @click="selectMenu('profile')"
              class="menu-item"
            >
              <font-awesome-icon :icon="['fas', 'user-circle']" />
              <span class="menu-text">PROFILE SETTINGS</span>
            </div>
          </div>
        </div>

        <!-- Right Column (Content Area) -->
        <div class="dashboard-content">
          <component :is="activeComponent" :userId="editingUserId" @goBack="goBackToUserList"/>
        </div>
      </div>

      <!-- Load LLBuilder in full screen when selected -->
      <LLBuilder v-if="selectedMenu === 'llbuilder'" @goHome="selectMenu('homepage')" />

      <!-- Footer Banner (only if dashboard layout is shown) -->
      <footer v-if="selectedMenu !== 'llbuilder'" class="dashboard-footer">
        <div class="footer-content">
          <img :src="logo" alt="Company Logo" class="company-logo" />
          © 2024 Medusa Digital. All Rights Reserved ©
        </div>
      </footer>
    </div>
  </v-app>
</template>

<script>
import { ref, provide, computed, onMounted } from 'vue';
import axios from 'axios'; // Ensure axios is imported

// Import all avatars statically
import Avatar1 from '@/assets/MD-Avatar-1.webp';
import Avatar2 from '@/assets/MD-Avatar-2.webp';
import Avatar3 from '@/assets/MD-Avatar-3.webp';
import Avatar4 from '@/assets/MD-Avatar-4.webp';
import Avatar5 from '@/assets/MD-Avatar-5.webp';
import Avatar6 from '@/assets/MD-Avatar-6.webp';
import Avatar7 from '@/assets/MD-Avatar-7.webp';
import Avatar8 from '@/assets/MD-Avatar-8.webp';
import Avatar9 from '@/assets/MD-Avatar-9.webp';
import Avatar10 from '@/assets/MD-Avatar-10.webp';

import YagaHomePage from '@/views/YagaHomePage.vue';
import FirstSteps from '@/views/FirstSteps.vue';
import LLBuilder from '@/views/LLBuilder.vue';
import DigitalTwin from '@/views/DigitalTwin.vue';
import IIoTDataSources from '@/views/IIoTDataSources.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import AddYagaUser from '@/views/AddYagaUser.vue'; // Import the new component
import EditYagaUser from '@/views/EditYagaUser.vue'; // Import the edit user component
import logo from '@/assets/Logo_Medusa_Digital_Dark.webp'; 

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export default {
  name: 'YagaDashboard',
  components: {
    LLBuilder,
    FontAwesomeIcon,
  },
  setup() {
    const avatarNumber = ref(localStorage.getItem('selectedAvatar') || 1);
    provide('avatarNumber', avatarNumber);

    const avatarPaths = [
      Avatar1, Avatar2, Avatar3, Avatar4, Avatar5,
      Avatar6, Avatar7, Avatar8, Avatar9, Avatar10
    ];

    const selectedAvatarPath = computed(() => avatarPaths[avatarNumber.value - 1]);

    // Check if the user is a superuser
    const isSuperUser = ref(false);
    onMounted(async () => {
      try {
        const token = localStorage.getItem('jwt');  // Retrieve the JWT token from local storage
        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await axios.get('/yaga-auth/profile', {
          headers: {
            Authorization: `Bearer ${token}`  // Include token in the request headers
          }
        });

        isSuperUser.value = response.data.role === 'SuperAdmin';
      } catch (error) {
        console.error('Error checking user role:', error.response ? error.response.data : error.message);
      }
    });

    return {
      selectedAvatarPath,
      isSuperUser
    };
  },
  data() {
    return {
      selectedMenu: 'homepage',
      logo,
      editingUserId: null,
    };
  },
  computed: {
    activeComponent() {
      if (this.selectedMenu === 'yagausers' && this.editingUserId) {
        return EditYagaUser;
      }
      switch (this.selectedMenu) {
        case 'yagausers':
          return AddYagaUser;
        case 'firststeps':
          return FirstSteps;
        case 'digitaltwin':
          return DigitalTwin;
        case 'iiotdata':
          return IIoTDataSources;
        case 'profile':
          return ProfileSettings;
        default:
          return YagaHomePage;
      }
    }
  },
  methods: {
    selectMenu(menu) {
      this.selectedMenu = menu;
      this.editingUserId = null; // Reset editing user ID when changing menu
    },
    editUser(userId) {
      if (this.isSuperUser) {
        this.editingUserId = userId;
      } else {
        console.error('Only SuperAdmins can edit users.');
      }
    },
    goBackToUserList() {
      this.editingUserId = null;
    }
  }
};
</script>

<style scoped>
.dashboard-layout {
  background: linear-gradient(to bottom, #000814, #0d1b2a); /* Background gradient */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dashboard-container {
  display: flex;
  flex-grow: 1; /* Ensures the content area grows to fill available space */
}

.dashboard-menu {
  width: 20%;
  background: linear-gradient(to bottom, #000814, #000000); /* Gradient background for left menu */
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Menu is placed at the bottom */
  border-top-right-radius: 15px; /* Rounded upper right corner */
  border-bottom-right-radius: 15px; /* Rounded lower right corner */
  height: 93%;
}

.avatar-border {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Yellow border background */
  border-radius: 50%;
  padding: 10px; /* Adjust to control the size of the border */
  width: 170px;
  height: 170px;
  margin: 0 auto 20px auto;
}

.avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.menu-list {
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 15px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
  border: none;
  border-top-right-radius: 15px; /* Rounded upper right corner */
  border-bottom-right-radius: 15px; /* Rounded lower right corner */
  background: linear-gradient(to bottom, #ffc300, #ffd60a); /* Default yellow background */
  color: #000814; /* Default text color */
  width: 100%; /* Ensure all buttons have the same width */
  display: flex;
  justify-content: flex-start;
  align-items: center; /* Align icons and text vertically */
  transition: background-color 0.3s ease; /* Smooth transition for button click */
  font-weight: bold; /* Bold font for menu items */
  font-size: 16px; /* Font size for menu items */
}

.menu-text {
  margin-left: 15px; /* Adds padding between the icon and the text */
}

.menu-item.active {
  background: linear-gradient(to bottom, #9ad7ff, #03abff); /* Selected blue background */
  color: #000814; /* Selected text color */
}

.menu-item:hover:not(.active) { /* Apply hover only if not active */
  background: linear-gradient(to bottom, #ffd966, #ffdd7b); /* Hover yellow background */
  color: #000814; /* Hover text color */
}

.dashboard-content {
  width: 80%;
  padding: 20px;
  height: 90%;
}

.dashboard-footer {
  background: linear-gradient(to bottom, #000814, #0d1b2a);
  color: white;
  text-align: center;
  padding: 5px 10px; /* Compressed padding for a smaller footer */
  font-size: 16px; /* Set your desired font size here */
  font-weight: bold;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Ensure footer stays on top */
  height: 7%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-logo {
  width: 120px; /* Adjust the size as needed */
}

.dashboard-footer span {
  margin-left: auto;
}
</style>
